<template>
    <div class="component-root dynamic-table">
            <div class="fixed-head">
                <div class="cell-0 cell" ><span>序号</span></div>
                <div class="cell-1 cell"><span>样品名称</span></div>
                <div class="cell-2 cell"><span>销售者</span></div>
                <div class="cell-3 cell"><span>检测项目</span></div>
                <div class="cell-4 cell"><span>检测值</span></div>
                <div class="cell-5 cell"><span>判定结论</span></div>
                <div class="cell-6 cell"><span>产地</span></div>
                <div class="cell-7 cell"><span>检测时间</span></div>
            </div>
            <div class="scroll-body" ref="scroll">
                <!-- <div class="current-show" ref="curse"></div> -->
                <div :id="'row'+index%2" class="item row" v-for="(item, index) in rows" :key="item.check_record_id" :class="{'red-row': item.check_result==='不合格', line:index===curseIndex}">
                    <div class="cell-0 cell" ><span>{{pageNo*15+index+1}}</span></div>
                    <div class="cell-1 cell"><span>{{item.sample}}</span></div>
                    <div class="cell-2 cell"><span>{{item.send_company}}</span></div>
                    <div class="cell-3 cell"><span>{{item.check_item}}</span></div>
                    <div class="cell-4 cell"><span>{{item.check_value}}{{item.unit}}</span></div>
                    <div class="cell-5 cell"><span>{{item.check_result}}</span></div>
                    <div class="cell-6 cell"><span>{{item.supplier || '本地'}}</span></div>
                    <div class="cell-7 cell"><span>{{item.check_time}}</span></div>
                </div>
            </div>
            <div class="table-footer"><div><input type="text" :value="pageNo+1"></div></div>
    </div>
</template>

<script>
const dateFormat=new Intl.DateTimeFormat('zh-u-nu-latn',{dateStyle:'medium',timeStyle:'medium'})
export default{
    name:'DynamicTable',
    props:{
        action:{
            type:String,
            default:'curse'
        },
        intervalTime:{
            type:Number,
            default:2
        },
        data:[],
        alias:[],
        columns:[],
        curPage:0,
    },
    created(){
        this.rows.push(...this.data.slice(0,15))
        this.initData(this.rows)
    },
    mounted(){
        this.startScroll()
    },
    methods:{

        addData(){
            

        },

        result:(val) => {
              switch (val) {
                case '1':
                  return '合格';
                case '2':
                  return '不合格';
                default:
                  return '待定';
              }
            },
        date:(val)=>{
            return dateFormat.format(val)
        },
        initData(val){
            for(let i of val)
            {
                i['check_time']=this.date(i['check_time'])
                i['check_result']=this.result(i['check_result'])
            }
        },
        startScroll(){
            // let scrollDoc=this.$refs.scroll
            // let height=scrollDoc.getBoundingClientRect().height/10;
            // let max=Math.max(this.rows.length-10,0)*height
            // let curse=this.$refs.curse
            // if(this.rows.length>0)
            // {
            //     clearInterval()
            //     setInterval(()=>{
            //     this.curseIndex++
            //         if(this.curseIndex>=this.rows.length)
            //         {
            //             this.nextPage()
            //             curse.style.top='0'
            //             scrollDoc.scrollTop=0
            //             this.curseIndex=0
            //         }else{
            //             curse.style.top=new String(10*this.curseIndex)+'%'
            //             if((scrollDoc.scrollTop)<max)
            //                 scrollDoc.scrollTop+=height
            //         }
            //         // this.$emit('firstItemChange',this.rows[this.curseIndex])
            // },4000)}
            if(this.timer) clearInterval(this.timer)
            this.timer = setInterval(()=>{

                this.curseIndex++
                if(this.curseIndex>=this.rows.length){
                     this.nextPage()
                     this.curseIndex=0
                }
            },4000)
        },
        nextPage(){
            if(this.rows.length===this.data.length)
                return
            this.rows=[]
            let i=++this.pageNo*15
            if(this.data.length-1<i)
                {i=0
                    this.pageNo=0
                }
        
            this.rows.push(...this.data.slice(i,i+15))
            this.initData(this.rows)
        }
    },

    watch:{
       alias(val){
        if(val.length!==this.columns.length)
            throw new Error("alias' length "+this.alias.length+"is diff with columns'"+this.columns.length)
       },
       data(){
        this.rows=[]
        this.rows.push(...this.data.slice(0,15))
        this.initData(this.rows)
        this.startScroll()
       }
    },

    data:()=>{
        return {
        rows:[],
        pageNo:0,
        curseIndex:0,
        page:0,
        pageSize:10 ,
        timer:null
        }
    }
}
</script>

<style lang="less" scoped>
.component-root{
    --myself-table-border-color:#8594e8;
    --myself-table-header-background-color:#043c8f;
    --myself-table-stripe-color-deeper:#1f0248;
    --myself-table-stripe-color-shallow:#023e6f;
    --myself-table-stripe-color-simple:#1666c5;
    --myself-table-font-color-normal:white;
    --myself-table-font-color-wrong:red;
    --myself-table-head-font-color:white;
    position: relative;
    width:calc(100% - 4px);
    height:calc(100% - 4px);
    border: 2px solid var(--myself-table-border-color);
    text-align: center;
    font-size: 2vh;
    color:var(--myself-table-font-color-normal);
    .fixed-head{
        width:100%;
        height:10%;
        color:var(--myself-table-head-font-color);
        display: inline-flex;
        background-color: var(--myself-table-header-background-color);
        font-weight: bold;
    }
    #row0{
        background-color: var(--myself-table-stripe-color-shallow);
        // background: linear-gradient(to right, #63A8FF,#96C8FF;);
    }
    #row1{
        background-color: var(--myself-table-stripe-color-simple);
        // background: linear-gradient(to right, #96C8FF, #63A8FF);
    }
    .red-row{
        color:var(--myself-table-font-color-wrong);
    }
    .line {
        border: 1px solid #08f7f7;
    }

    .cell{
        height:100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        span{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        
    }
    .cell-0{
        width:8%;
    }
    .cell-1{
        width:8%;
    }
    .cell-2{
        width:17%;
    }
    .cell-3{
        width:20%;
    }
    .cell-4{
        width:8%;
    }
    .cell-5{
        width:9%;
    }
    .cell-6{
        width:8%;
    }
    .cell-7{
        width:22%;
    }
    .scroll-body{
        position: relative;
        width: 100%;
        height: 84%;
        display: flex;
        flex-direction: column;
        overflow:hidden ;
        background: #013145;
    }

    .row {
        flex:0 0 10%;
        width: 100%;
        display: inline-flex;
    }
    .current-show{
        position:absolute;
        height:calc(9% - 6px);
        width:calc(100% - 6px);
        position: al;
        top:0;
        background-color: transparent !important;
        border:1px #08f7f7 solid;
    }
    .table-footer{
        width:100%;
        height:6%;
        background-color: #043c8f;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:center ;
        input{
            color: var(--myself-table-font-color-normal);
            min-width:50px;
            width:10%;
            height: 90%;
            background-color: #2961b5;
            text-align:center;
        }
    }
}
</style>


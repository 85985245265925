import Instance from "./axios"

const getOrgOverview=(start,end,orgId)=>{
    if(orgId instanceof Array)
        return Instance.post('overview/org/sample',{start,end,orgId})
    else{
        let org=[]
        org.push(orgId)
        return Instance.post('overview/org/sample',{start,end,'orgId':org})}
}

const getOrgOverViewByTime=(start,end,orgId)=>{
    return Instance.post('overview/org',{start,end,"orgId":[orgId]})
}

const getSubOverview=(start,end,areaId)=>{

    if(areaId===undefined||areaId===null)
        throw new Error("传入参数错误")
    let area =areaId
    if(!(areaId instanceof Array))
            area=[areaId]
    if(areaId.endsWith("0000"))
    return Instance.post('/overview/province',
        {
            start,
            end,
            'areaId':area,
        }
        )
    if(areaId.endsWith("00"))
    return Instance.post("/overview/city",{
        start,
        end,
        'areaId':area,
    })
    return Instance.post("/overview/county",{
        start,
        end,
        'areaId':area,
    })
}
const getAreaOverview=(start,end,areaId)=>{
    return new Promise((resolve,reject)=>{
        if(end<=start)
            reject(new Error("时间域非法"))
        if(areaId instanceof Array)
            Instance.post("/overview/area",{areaId,end,start}).then(res=>{
                resolve(res.data)
            },e=>{reject(e)})
            else
        reject(new Error("areaId should be Array"))
    })
} 

export {getSubOverview,getOrgOverview,getOrgOverViewByTime,getAreaOverview}
<template>
  <div class="root" >
    <div class="full-screen-button" @click="fullScreen($refs.screen)"><el-icon size="50"><FullScreen /></el-icon>
    <!-- <span>全屏</span> -->
    </div>
    
    <div class="settings" :style="{opacity:showSetting ? 1 :0}" @mouseenter="showSetting=true" @mouseleave="showSetting=false"><el-icon size="50" color="white" @click="settingVisable=true"><setting/></el-icon></div>
    <div ref="screen" class="screen" style="width:80%;height:90%;margin-left:10%">
      <div  v-show="showData"  style="height:100%;"><el-row style="height:10%" :gutter="5">
      <div class="title">食品安全检测信息公示屏</div>
    </el-row>
    <el-row style="width:120%; height:90%" >
      <el-col :span="20" >
        <dynamic-table  ref="dytable" :data="dataList" @firstItemChange="setFormData"></dynamic-table>
      </el-col>
    </el-row>
    <el-row style="height:8%" >
      <el-col :span="24">
        <!-- <div class="footer"><span>技术支持:</span><span>湖北盛同盛泰生物科技有限公司</span>&nbsp;<span>TEL:</span><span>027-88848828</span>&nbsp;<span>13387664575</span></div> -->
      </el-col>
    </el-row></div>
    <div v-show="!showData" class="lunhuan">
      <img src="@/assets/pictures/lunhuan.png" alt="">
    </div>
    </div>
    

    <el-dialog style="display:flex; flex-direction: column; justify-content: space-between; align-items: center;" v-model="settingVisable" width="30%" >
      <template #title>
        <div style="height:3vh; line-height: 3vh; font-size: 3vh; "><span >选择展示单位</span></div>
    </template>
      <el-select no-data-text="尚未绑定任何实体，请联系管理员!" v-model="defaultOrgan">
          <el-option v-for="item in organization" :key="item.org_id" :value="item.org_id" :label="item.org_name"></el-option>
      </el-select>
    </el-dialog>

  </div>
</template>
 
<script>
import DynamicTable from 'component/DynamicTable.vue';
import { Setting,FullScreen } from '@element-plus/icons-vue';


import {getOrgOverViewByTime}  from'network/overview'
import { getDataList } from 'network/checkdata';

const timeTrigger=["9:30","11:00","14:00"]

export default {
  components: { DynamicTable,FullScreen,
      Setting},
  computed: {
    midNight(){
      return new Date(new Date().toDateString())
    },
    currentTime() {
      return new Date()
    },
    org() {
      if (this.$store.getters.getUserRole == 'org') {
        return this.$store.getters.getUserAuth
      } else
        return []
    },
    companyCache(){
      return this.$store.getters['companies/userCompanies']
    },
    organization(){
      let organ=[]
      for(let item of Object.values(this.companyCache))
      {
        organ.push(...item)
      }

      organ=organ.filter(e=>this.org.findIndex(es=> es==e.org_id)>=0)
      return organ
    }
  },
  methods: {
    setLunhuan(){
        //5分钟切为图片
        this.timer1 = setTimeout(()=>{
          //只有全屏才会切换
          if(document.fullscreenElement){
          this.showData = false
          }
          //30秒切为列表
          this.timer2 = setTimeout(()=>{
            this.showData = true
            //切回列表开启下一次循环
            if(document.fullscreenElement){
              this.setLunhuan()
            }
          },30*1000)
        },5*60*1000)
      
      
    },

    fullScreen(element){
        this.$refs.screen.requestFullscreen().then(
          
          res=>{
            console.log("进入全屏")
            this.setLunhuan()
            },e=>{this.$message.warning("不能开启全屏")}
        )
    },
    
    getOverviews(){
      let promise=[]
      let oneDayLong=86400000
      let time=[[],[]]
      let overviews=[]
      let date=this.midNight.getTime()
      for (let i = 11; i >0 ; i--) {
        let e=date-i*oneDayLong
        time[0].push(e)
        time[1].push(new Date(e).toLocaleDateString())
      }

      for (let i = 0; i < time[0].length-1; i++) {
        promise.push(getOrgOverViewByTime(time[0][i],time[0][i+1],this.defaultOrgan).then(
          res=>{return res.data},e=>{return e}
        ))
      }
      Promise.allSettled(promise).then(
        results=>{
          for (let i = 0; i < results.length; i++) {
            if(results[i].value)
           { let e=results[i].value[this.defaultOrgan]
              e.time=time[1][i]
              overviews.push(e)}
              else if(results[i].reason)
              {
                console.log("未获取数据:"+time[1][i])
              }
          }
          if(overviews.length>0)
          this.setOverviewData(overviews)
          else
          console.log("未获取到任何数据")
        }
      )
    },
    setOverviewData(data){
      let rate=[]
      let total=[]
      for(let i = 0; i < data.length; i++) {
        let t=data[i].unqualified+data[i].qualified
        let r=(t)==0?0:Math.round(1000*data[i].qualified/(t))*100
        rate.push({"time":data[i].time,"合格率":r,"检测量":t})
        total.push({"time":data[i].time,'合格':data[i].qualified,'不合格':data[i].unqualified})
      }
      this.rate=rate
      this.amount=total
    },
    setFormData(data){
      this.formData=data
    },
    intervalFunction(){
      let now=new Date()
      let h=now.getHours()
      let m=now.getMinutes()
      m=Math.round(m/10)
      let time=h.toString()+":"+m+"0"
      if(timeTrigger.find(e=>e===time))
      this.getData(this.midNight,new Date())
    },

    getData(start, end) {
      setTimeout(async()=>{
        await getDataList([start, end], null, 0, this.defaultOrgan, 1000).then(
        res => {
          this.lastTime=end
          this.dataList=res.data.dataList
        },e=>{
          console.log(e.message)
        }
      )}  ,10000);
    }
  },
  watch:{
    defaultOrgan(val){
      this.getData(this.midNight,new Date())
    }
  },
  created(){
    this.getData(this.midNight,new Date())
  },
  mounted() {  
    
    setInterval(
      this.intervalFunction()
      ,600000)
  },
  data() {
    return {
      timer1:null,
      timer2:null,
      showData:true,
      dataList: [],
      lastTime:{},
      statistics:[
        { value: 0, name: "合格" },
        { value:0, name: "不合格" },
      ],
      defaultOrgan:'',
      formData:{},
      rate:[],
      amount:[],
      company:"",
      settingVisable:false,
      showSetting:false
    }
  }
}
</script>

<style lang="less" scoped>
.root {
  position: relative;
  width: 99%;
  height: 100%;
  background-color: #1666c5;
  .full-screen-button{
    // width:56px;
    // height:32px;
    // font-size: 24px;
    // line-height: 32px;
    position: absolute;
    top:50%;
    left: 12vh;
    // border-radius: 3px;
    // border:#ffffff solid  1px;
    color :#ffffff;
    z-index: 10;
    cursor: pointer;
  }
  .settings{
    width:56px;
    height:32px;
    font-size: 24px;
    line-height: 32px;
    position: absolute;
    top:2%;
    right: 2%;
    z-index: 10;
    cursor: pointer;
  }
  .title{
    width: 100%;
    height:100%;
    line-height: 7vh;
    text-align: center;
    font-size: 5vh;
    align-items: center;

      color: #ffffff;
      font-size: 5vh;
    font-weight: bold;
    text-shadow: 0px 4px 4px #3f7da8;
    text-align: center;

  }
  .footer{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    span{
      color: white;
      font-size: 2vh;
    }
  }

}
.screen {
  position: relative;
}
.lunhuan {
  width: 100%;
  height: 100%;
  position: absolute;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>